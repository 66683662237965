//import logo from './logo.svg';
import './App.css';
import React from 'react';

import InteractiveImage from './Components/InteractiveImage';

function App() {
  return (
    <React.Fragment>
      <InteractiveImage />
    </React.Fragment>
  );
}

export default App;
