import React, { useState, useEffect } from 'react';
import '../Styles/InteractiveImage.css'; // Import the CSS file for styling

const InteractiveImage = () => {
  // Initialize all frames to glow on the first render
  const [glowStatus, setGlowStatus] = useState([false, false, false]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Use a functional update to avoid depending on `glowStatus` directly
      setGlowStatus(glowStatus => glowStatus.map(() => Math.random() > 0.5));
    }, 3000);
  
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
    // No need to include `glowStatus` in the dependency array
  }, []);

  return (
    <div className="interactive-image-container">
      <a href="/"><img
        src="/logo.png"
        alt="Yi Commerce Solutions Ltd. Logo"
        className="logo"
      /></a>
      <a href="https://www.linkedin.com/company/yi-commerce-solutions"><img
        src="/fuzz1.webp"
        alt="A modern office setup within what appears to be a large warehouse space. The warehouse behind the workspace is stocked with shelves full of boxes, implying a setting that combines administrative work with logistics or inventory management. Clicking here will take you to the about us page (LinkedIn)."
        className={`frame-1 inner-glow ${glowStatus[0]  ? 'active' : ''}`}
      /></a>
      <a href="https://forms.gle/936ZTzkT43hCeLxz7"><img
        src="/centre_desk.webp"
        alt="A sleek black office chair facing a desk with a desktop computer monitor. A couple small potted plant providing a touch of greenery. Beneath the desk, a black filing cabinet with drawers is visible. Clicking here will take you to the contact page (web form)."
        className={`frame-2 glow ${glowStatus[1] ? 'active' : ''}`}
      /></a>
      <a href="https://www.amazon.ca/dp/B0BRGGKRS5"><img
        src="/product_dalishu_tilted.png"
        alt="Prominently in the foreground, a yellow package with Chinese and English of 'Da Li Shu' sits atop an open cardboard box, hinting product from international shipping. Clicking here will take you to the Amazon page for the product, which is a 5 star green tea product available in Canada with free shipping."
        className={`frame-3 glow ${glowStatus[2] ? 'active' : ''}`}
      /></a>

    </div>
  );
};

export default InteractiveImage;
